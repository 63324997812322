<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        keyword-placeholder="姓名、订单编号"
        :tool-list="[ 'keyword']"
        :filter-keys="['payMethod']"
        @on-search="renderTable(1)">
        <template #pre-form>
          <el-form-item label=" " label-width="30">
            <el-radio-group size="small" v-model="queryInfo.tradeType" @change="renderTable(1)">
              <el-radio-button label="partTimeTuitionFee">普通非全日制</el-radio-button>
              <el-radio-button label="partTimeCustomTuitionFee">自定义非全日制</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item v-if="queryInfo.tradeType==='partTimeCustomTuitionFee'" label="自定义非全日制专业" label-width="170">
          <el-select v-model="queryInfo.majorName" clearable filterable size="mini" style="width: 260px;">
            <el-option v-for="majorName in customizedMajorList" :key="majorName" :label="majorName" :value="majorName" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryInfo.tradeType==='partTimeTuitionFee'" label="非全日制专业" label-width="170">
          <el-select v-model="queryInfo.majorId" clearable filterable size="mini" style="width: 260px;">
            <el-option v-for="{majorName,id} in partTimeMajorList" :key="id" :label="majorName" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="startDate" label-width="60">
          <el-row>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                size="mini"
                placeholder="开始时间"
                v-model="queryInfo.startDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                size="mini"
                placeholder="结束时间"
                v-model="queryInfo.endDate"
                style="width: 100%"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="订单状态" prop="ordrStcd">
          <el-select size="small" v-model="queryInfo.ordrStcd" clearable>
            <el-option v-for="{ name, type } in orderStatusList" :label="name" :value="type" :key="type" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-tag class="successNum">成功支付人数：{{ countForm.successNum }}</el-tag>
        <el-tag type="success">成功支付金额：{{ countForm.successAmountSum }}元</el-tag>
        <el-button type="success" size="small" @click="handleExportExcel">按条件导出</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <!--      <el-table-column type="index" label="ID" width="50" />-->
      <el-table-column prop="orderNo" label="订单号" width="220px" fixed="left" />
      <el-table-column prop="totalPrice" label="支付金额" width="100" fixed="left">
        <template v-slot="{ row }">
          <b>{{ payMethod === '移动支付:中行' ? row.amount : row.totalPrice }}</b>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" align="center" width="100" fixed="left">
        <template v-slot="{row}">
          {{ orderStatusList[Number(row.ordrStcd || 1) - 1].name }}
        </template>
      </el-table-column>
      <el-table-column label="订单流水号" width="270px">
        <template v-slot="{row}">
          {{ row.primOrdrNo === 'null' ? '--' : row.primOrdrNo }}
        </template>
      </el-table-column>
      <el-table-column label="订单创建时间" min-width="220px">
        <template v-slot="{row}">
          {{ row.ordrGenTm === 'null' ? '--' : row.ordrGenTm }}
        </template>
      </el-table-column>
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="idNumber" label="身份证" min-width="200" />
      <el-table-column prop="examineCode" label="考生号" min-width="120px" />
      <el-table-column prop="majorName" label="专业" min-width="250px" />
      <el-table-column prop="collegeName" label="学院" min-width="160px" />
    </el-table>
  </table-view>
</template>

<script>
import {
  getPartTimeMajors,
  getPartTimeMajorsCustomized
} from '@/api/studentPartTime'
import {
  getPayOrderListApi,
  getTuitionOrderOrderListApi,
  getBocOrderOrderListApi
} from '@/api/finance-manage/studentOrder'
import tableView from '@/vue/mixins/table-view'
import {
  orderStatusList,
  payTypeList
} from '@/views/pages/stuInfoPartTime/pay-order/component/options'

export default {
  mixins: [tableView],
  data() {
    return {
      payMethod: '移动支付',
      addData: {
        id: '',
        isOpen: false,
        title: '导出订单'
      },
      customizedMajorList: [], // 自定义非全日制缴费 专业 list 不带id
      partTimeMajorList: [], // 非全日制 专业 list 带id
      queryInfo: {
        startDate: null,
        endDate: null,
        ordrStcd: null,
        majorId: null,
        majorName: null,
        tradeType: 'partTimeTuitionFee', // 重置时不能清空
        payMethod: '移动支付'
      },
      countForm: {
        successNum: 0,
        failNum: 0,
        successAmountSum: 0
      },
      orderStatusList,
      payTypeList
    }
  },
  async mounted() {
    await Promise.all([
      this.renderTable(1),
      this.getMajorList(),
      this.getMajorListCustomized()
    ])
  },
  methods: {
    getTableData(apiMethod, pageNum) {
      if (pageNum) this.queryInfo.pageNum = pageNum
      this.loading = true
      apiMethod(this.queryInfo).then(res => {
        ({
          list: this.tableData,
          total: this.total,
          successNum: this.countForm.successNum,
          successAmountSum: this.countForm.successAmountSum,
          payMethod: this.payMethod
        } = res.data)
      }).catch((e) => {
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取订单列表
    renderTable(pageNum) {
      switch (this.queryInfo.tradeType) {
        case 'partTimeCustomTuitionFee':
          this.queryInfo.majorId = null
          break
        case 'partTimeTuitionFee':
          this.queryInfo.majorName = null
          break
      }
      switch (this.queryInfo.payMethod) {
        case '移动支付':
          this.getTableData(getPayOrderListApi, pageNum)
          break
        case '移动支付:中行':
          this.getTableData(getBocOrderOrderListApi, pageNum)
          break
        default:
          this.getTableData(getTuitionOrderOrderListApi, pageNum)
      }
    },
    // 获取继续教育专业 list
   async getMajorList() {
        try {
          const { data } = await getPartTimeMajors()
          this.partTimeMajorList = [
            {
              id: '',
              majorName: '所有专业'
            },
            ...data
          ]
        } catch (e) {
          console.error(e)
        }
    },
    // 获取继续教育专业 list 专用于自定义非全日制缴费
    async getMajorListCustomized() {
      try {
        const { data } = await getPartTimeMajorsCustomized()
        this.customizedMajorList = data
      } catch (e) {
        console.error(e)
      }
    },
    handleExportExcel() {
      this.$http.exportExcelHttp.exportPayOrder(this.queryInfo, '继续教育学院-支付订单').then()
    }
  }
}
</script>

<style lang="scss" scoped>
.successNum {
  margin-right: 20px;
}
</style>
